/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FORMAT_GRAPH_DATA } from 'utilities';
import Masonry from 'react-masonry-component';
import { BASE_URL } from 'config';
import {
  Banner,
  TestimonialCard,
} from 'components';
// import {
  // BestInNZ,
  // TourList,
  // TestimonialList,
  // InstagramList,
// } from 'layouts';

class Born2RoamOrHakaTours extends PureComponent {
  constructor(props) {
    super(props);
    const REVIEWS = FORMAT_GRAPH_DATA(props.data.allReviews);
    this.state = {
      reviews: REVIEWS,
    };
  }

  render() {
    const { reviews } = this.state;
    return (
      <Layout page="adventure-vs-haka-plus">

        <Helmet
          title="What’s the difference between Adventure and Haka Plus tours?"
          meta={[
            {
              name: 'description', content: 'Haka Tours provides inbound tour operator services within New Zealand. Partner with us to get the best New Zealand tour experience for your clients.',
            },
            { name: 'og:title', content: 'What’s the difference between Adventure and Haka Plus tours?' },
            {
              name: 'og:description', content: 'Haka Tours provides inbound tour operator services within New Zealand. Partner with us to get the best New Zealand tour experience for your clients.',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/adventure-vs-haka-plus/` }]}
        />

        <Banner backgroundImage="https://static-hakatours.imgix.net/queenstown-sub-2-haka-tours-1.jpg?w=1900&h=600&fit=crop&auto=format">
          <h1 className="c-heading c-heading--h1">Choosing between a Born to Roam Tour and a Haka Tour</h1>
        </Banner>
        <section className="l-section-main">
          <div className="l-container l-container--small">
            <div className="u-text--center">
              <h2 className="c-heading c-heading--h2">WHAT'S THE DIFFERENCE?</h2>
            </div>
            <br /><br />
            <div className="u-text--center">
              Our Haka Tours are fully guided tours using our own Haka Tours vehicles and our experienced local guides.
              You will join up to 15 other travellers , meet some new people and get whisked around the beautiful countryside of New Zealand, all by us! WOW.
            </div>
            <br /><br />
            <div className="u-text--center">
              Born to Roam is an ‘independent touring’ model.
              We put the itinerary together for you including accommodation, activities and meals, then you choose whether to use the transportation network in New Zealand, or a hire car of your own!
              There’s no guide and no other people to worry about. We do the hard yards of putting the itineraries together and you see New Zealand, your way.
              If this sounds more like you, pack your bags and get booking, because you were Born to Roam!
            </div>
          </div>
          <div className="l-testimonial-list" style={{ padding: '0 0 70px' }}>
            <div className="l-container">
              <div className="l-testimonial-list__header">
                <h2 className="c-heading c-heading--h2">
                  Here’s what you’ve said about us:
                </h2>
              </div>
              <Masonry
                className="l-testimonial-list__grid"
                elementType="div"
                disableImagesLoaded={false}
                updateOnEachImageLoad={false}
                options={{
                  gutter: 20,
                  transitionDuration: 0,
                }}
              >
                {reviews.map(item => (
                  <TestimonialCard
                    key={item.id}
                    data={item}
                    textLimit={260}
                  />
                ))}
              </Masonry>

              <div className="l-testimonial-list__header" style={{ marginTop: '3rem' }}>
                <h2 className="c-heading c-heading--h2">
                  Now go and book your adventure of a lifetime!
                </h2>
              </div>

              <div class="blog-container">
                <div>
                  <Link
                    to='born-to-roam'
                    className="c-button c-button--primary c-button--wide"
                  >
                    See Born 2 Roam Tours
                  </Link>
                </div>
                <div>
                  <Link
                    to='new-zealand-tours'
                    className="c-button c-button--primary c-button--wide"
                  >
                    See Haka Tours
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    );
  }
}

Born2RoamOrHakaTours.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allReviews(
      limit: 3,
      sort: {
        fields: date_published,
        order: DESC
      }
      filter: {
        id: {
          in: ["__Reviews__5cafbc03da8c320001046408","__Reviews__5cc20ab7fb436d0001c9a456","__Reviews__5c522f315410400001c9ca6b"]
        }
      }
    ) {
      edges {
        node {
          id
          api_id
          author
          country
          text
          image_url
          date_published
        }
      }
    }
  }
`;

export default Born2RoamOrHakaTours;
